import ARB_ADDRESS  from './bscAddress'

export function getAddress (chain) {
  return ARB_ADDRESS
}

export function getCurAddress () {
  const chain = localStorage.getItem('kepler_chain') || 'BSC'
  return getAddress(chain)
}
