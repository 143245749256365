
import ADDRESS  from '../mainnet/bscAddress'

export function getAddress () {
  return ADDRESS
}

export function getCurAddress () {
  return getAddress()
}
